/* Add Employer dialog */

.addEmployerDialog .k-dialog {
    border: 1px solid black;
}

.addEmployerDialog .k-dialog-titlebar {
    border-bottom: 1px solid black;
    color: black;
}

.addEmployerDialog .k-dropdown .k-input {
    height: auto;
    font-size: 12px;
}

/* Confirm Delete Employer dialog */

.confirmDeleteEmployerDialog .k-dialog {
    border: 1px solid black;
}

.confirmDeleteEmployerDialog .k-dialog-titlebar {
    border-bottom: 1px solid black;
    color: black;
}
