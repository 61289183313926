.k-list-container .k-nodata .k-button,
.k-dropdown .k-dropdown-wrap,
.k-button,
.k-button-group.k-state-disabled .k-button:not(.k-state-active),
.k-button-group .k-state-disabled:not(.k-state-active),
.k-toolbar .k-split-button .k-button,
.k-spreadsheet-filter,
.k-pivot-toolbar .k-button,
.k-fieldselector .k-list li.k-item,
.k-colorpicker .k-picker-wrap,
.k-combobox .k-select,
.k-datepicker .k-select,
.k-timepicker .k-select,
.k-datetimepicker .k-select,
.k-dateinput .k-select,
.k-dropdowntree .k-dropdown-wrap,
.k-editor .k-editor-toolbar-wrap a.k-tool,
.k-ct-popup .k-editor-toolbar a.k-tool,
.editorToolbarWindow.k-header.k-window-content a.k-tool,
.k-numerictextbox .k-select,
.k-handle,
.k-map .k-navigator,
.k-gantt-views li,
.k-scheduler-navigation .k-nav-today,
.k-scheduler-navigation .k-nav-prev,
.k-scheduler-navigation .k-nav-next,
.k-scheduler-views li,
.k-scheduler-footer li {
  background-color: #d6e5f3;
}

.k-dropdown .k-dropdown-wrap {
  background-color: white;  
}

.k-widget.k-dropdown,
.k-popup.k-list-container {
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.k-widget,
.k-block,
.k-panel {
  color: black;
}

.k-list .k-item:hover,
.k-list .k-item.k-state-hover,
.k-list-optionlabel:hover,
.k-list-optionlabel.k-state-hover,
.k-list-container .k-button:hover,
.k-list-container .k-button.k-state-hover,
.k-column-list-item:hover,
.k-columnmenu-item:hover,
.k-column-menu .k-menu:not(.k-context-menu) .k-item:hover,
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-hover,
.k-spreadsheet-filter-menu > .k-menu .k-item.k-state-hover,
.k-spreadsheet-filter-menu > .k-menu:not(.k-context-menu) .k-item.k-state-hover,
.k-spreadsheet-popup .k-button:hover,
.k-spreadsheet-popup .k-button.k-state-hover,
.k-menu-group .k-item:hover,
.k-menu-group .k-item.k-state-hover,
.k-menu.k-context-menu .k-item:hover,
.k-menu.k-context-menu .k-item.k-state-hover {
  color: black;
  background-color: #DDE8F5;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected,
.k-list-container .k-button:active,
.k-list-container .k-button.k-state-active,
.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-selected,
.k-spreadsheet-popup .k-button:active,
.k-spreadsheet-popup .k-button.k-state-active,
.k-spreadsheet-popup .k-button.k-state-selected,
.k-menu-group .k-item.k-state-selected,
.k-menu.k-context-menu .k-item.k-state-selected {
  color: black;
  background-color: #0078d7;
}

.k-list .k-item:hover.k-state-selected,
.k-list .k-item.k-state-hover.k-state-selected,
.k-list-optionlabel:hover.k-state-selected,
.k-list-optionlabel.k-state-hover.k-state-selected {
  color: black;
  background-color: #0078d7;
}

.k-button {
  color: #444;
  font-weight: bold;
  background-image: none;
  border-color: #000;
  border-radius: 1px;
  border-style: solid;
}

.k-button:hover, .k-button:focus {
  color: #222;
  background-color: #E0EFFF;
  background-image: none;
  border-color: #000;
  border-radius: 1px;
  border-style: solid;
}

.k-dialog-button-layout-stretched .k-button:not(:hover):not(.k-state-hover):not(:active):not(.k-state-active) {
  border-color: inherit;
}

.k-grid {
  color: black;
  max-height: 500px;
}

.k-grid-header {
  color: #444;
}

.k-grid tr.k-alt {
  background-color: #eff5fa;
}

.k-grid tbody tr:hover,
.k-grid th.k-state-selected,
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: #DDE8F5;
  color: black;
}

.k-grid-header,
.k-grid-toolbar,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  background-color: #d6e5f3;
}

.k-dropdown .k-dropdown-wrap {
  background-image: none;
}

.k-dropdown .k-dropdown-wrap:hover {
  background-color: #E0EFFF;
  background-image: none;
}

.k-dropdown .k-dropdown-wrap.k-state-focused {
  background-color: #E0EFFF;
}

.k-textbox::selection,
.k-input::selection,
.k-textarea::selection {
  background-color: #0078d7;
}

.k-autocomplete .k-input,
.k-dateinput-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-multiselect-wrap .k-input,
.k-numeric-wrap .k-input {
  color: black;
}

.k-toolbar,
.k-editor-toolbar {
  background-color: #eff5fa;
}

.k-dialog-titlebar {
  background: #dde8f5 url('../../../assets/images/bg-subheader.gif') left bottom repeat-x;
}

.k-upload .k-dropzone {
  background-color: #eff5fa;
}

.k-treeview .k-in.k-state-selected {
  background-color: #0078d7;
}

.k-popup,
.k-column-menu .k-menu:not(.k-context-menu),
.k-menu-group,
.k-menu.k-context-menu {
  color: black;
}

.colorPickerPopup .k-popup {
  padding-top: 0px;
}

.colorPickerPopup .k-hex-value {
  width: 96px !important;
}

.colorPickerPopup a.k-draghandle {
  color: transparent !important;
}

.k-tooltip {
  color: white;
  background-color: black;
}

.k-callout {
  color: black;
}

.k-textbox, .k-input.k-textbox, .k-textarea {
    font-size: 12px;
}

.k-grid th.k-header {
    font-weight: bold;
}

.k-grid td[kendogridfiltercell] {
    padding: 4px 8px;
}

.k-grid .k-dropdown-operator.k-header {
    font-size: 12px;
    margin-right: 4px;
}

.k-grid .k-filtercell-operator button.k-button-icon {
    border-color: rgba(0, 0, 0, 0.08);
}

.k-grid td[kendogridcell],
.k-grid button.k-button-icon,
.k-grid th.k-header {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: auto;
}

/* Spacing fix for editable fields in Custom Text grid. */
.k-grid td.k-grid-edit-cell input.k-textbox {
    margin: 2px -8px;
    padding: 4px;
}

.featureSwitch.k-switch-on:hover .k-switch-container, .featureSwitch.k-switch-on .k-switch-container {
  color: #ffffff;
  background-color: #009e0f;
}

.featureSwitch.k-switch-off:hover .k-switch-container, .featureSwitch.k-switch-off .k-switch-container {
  color: #ffffff;
  background-color: #cf2a27;
}

.featureSwitch .k-switch-label-on, .featureSwitch .k-switch-label-off {
  text-transform: initial;
}

.featureSwitch.k-state-disabled .k-switch-container {
background-color: #898989;
}