/* You can add global styles to this file, and also import other style files */

html { background-color: lightgray; } 

h1{color: black;
    background-image: url('assets/images/bg-header.png');
    width:100%; 
    height: 26px;
    font-size: large;
    padding-left: 8px;
    margin-top: 10px;
    font-weight: bold;
 }
 